@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.modal-container {
  width: 880px;

  &.MOBILE {
    width: 320px;

    .content {
      max-height: 230px;
    }
  }

  .loading {
    width: 100%;
    height: 560px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error {
    width: 100%;
    height: 560px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .img {
      width: 64px;
      height: 64px;
      background: url('/img/icon/icon_bubble_pending.svg') no-repeat center;
      background-size: 64px;
      margin-bottom: 32px;
    }

    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
      margin-bottom: 8px;
    }
  }

  .content {
    width: 100%;
    padding: 24px;
    max-height: 560px;
    overflow: auto;
  }

  .agree-content {
    padding: 24px;

    &.MOBILE {
      max-height: 400px;
      overflow: auto;
    }

      .h1 {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        margin-bottom: 8px;
      }

    .title-desc {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #868E96;
      margin-bottom: 30px;
    }

    .table-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      display: inline-block;
      margin-bottom: 4px;
    }

    .table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;
      border: 1px solid black;

      tr > th {
        width: 267px;
        height: 53px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        border: 1px solid black;
        background: #F8F9FA;
      }

      tr td {
        height: 160px;
        border: 1px solid black;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;

        &.left {
          text-align: left;
        }
      }
    }

    .description-default {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .description1 {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color:#868E96;
    }
  }
}
